export class SurveyQuestionsModel {
  questionText?: string;
  questionType?: string;
  surveyQuestionOptions: { optionText: string }[];

  constructor(
    questionText?: string,
    questionType?: string,
    surveyQuestionOptions?: { optionText: string }[]
  ) {
    this.questionText = questionText;
    this.questionType = questionType;
    if (surveyQuestionOptions)
      this.surveyQuestionOptions = surveyQuestionOptions;
    else this.surveyQuestionOptions = [];
  }
}
